import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import ReactGA from "react-ga4";

// Google Analytics 초기화 및 페이지 뷰 로깅
ReactGA.initialize('G-YR0831JM0N'); // 여기에 실제 추적 ID를 사용하세요.
ReactGA.send('pageview'); // 현재 페이지의 페이지뷰를 전송합니다.

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
